import { Component } from '@angular/core'
import { CaseListComponent } from '../../../views/case-list/case-list.component'
import { NoDataFoundComponent } from '../../../shared/components/empty-state/no-data-found/no-data-found.component'
import { StorageService } from '../../../services/storage.service'
import { MonarchCase } from '../../../shared/interfaces'

@Component({
   selector: 'app-home-tab-recently-viewed',
   standalone: true,
   imports: [CaseListComponent, NoDataFoundComponent],
   templateUrl: './home-tab-recently-viewed.component.html',
})
export class HomeTabRecentlyViewedComponent {
   recentlyViewed: MonarchCase[] = []
   constructor(private storageService: StorageService) {}

   ngOnInit() {
      this.recentlyViewed = this.storageService.getRecentlyViewedCases()
   }
}
