<div>
   <ul role="list">
      @for (event of auditEvents; track $index) {
         <li>
            <app-audit-trail-feed-row
               [auditEvent]="event"
            ></app-audit-trail-feed-row>
         </li>
      }
   </ul>
</div>
