<div class="relative pb-8">
   <span
      class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
      aria-hidden="true"
   ></span>
   <div class="relative flex items-start space-x-3">
      <div class="relative">
         <!--Profile pic-->
         <img
            class="flex h-10 w-10 items-center justify-center rounded-full ring-8 ring-white"
            src="{{ auditEvent.user.profilePicPath }}"
            onerror="this.src='assets/profile/default_profile.png'"
            alt="user profile picture"
         />
      </div>
      <!--Information Stack-->
      <div class="min-w-0 flex-1 space-y-1">
         <!--VStack-->
         <div>
            <!--HStack-->
            <!--Name, Email, Action-->
            <div class="text-sm space-x-1">
               <span class="font-medium text-gray-900">{{
                  auditEvent.user.name
               }}</span>
               <span class="font-normal text-gray-700">{{
                  auditEvent.user.email
               }}</span>
               <span class="font-medium text-gray-900">{{
                  auditEvent.event.template
               }}</span>
            </div>
            <div class="mt-0.5 text-sm text-gray-500 space-x-1">
               <span>
                  {{ auditEvent.event.timestamp | date: 'medium' }}
               </span>
               <span
                  (click)="this.jsonDisplayed = !this.jsonDisplayed"
                  class="hover:text-indigo-700 hover:font-semibold"
               >
                  @if (jsonDisplayed) {
                     Hide Details
                  } @else {
                     Show Details
                  }
               </span>
            </div>
         </div>
         @if (this.jsonDisplayed) {
            <pre class="text-sm font-mono text-gray-700">{{
               auditEvent | json
            }}</pre>
         }
      </div>
   </div>
</div>
