import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

@Injectable({
   providedIn: 'root',
})
export class ConfigurationService {
   private endpoint: string = `${environment.protocol}://${environment.host}:${environment.port}/${environment.apiRoot}`
   constructor() {}

   getEndpoint = (): string => this.endpoint
   getAuthorizationEndpoint = (): string => this.endpoint
}
