<div>
   @if (recentlyViewed.length > 0) {
      <div class="flex flex-col justify-between">
         <div>
            <app-case-list [caseList]="recentlyViewed"></app-case-list>
         </div>
         <div class="flex justify-center w-full mt-3">
            <span
               class="text-gray-700 text-sm hover:text-indigo-800 hover:font-semibold"
            >
               Clear Recently Viewed
            </span>
         </div>
      </div>
   } @else {
      <app-no-data-found title="No cases recently viewed"></app-no-data-found>
   }
</div>
